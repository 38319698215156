.offcanvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transition-duration: .5s;
  height: 60px;
  padding: 12px 0;
  transform: translateX(-100%);
  position: fixed;

  @include media-breakpoint-down(sm) {
    height: auto;
  }


  &_active {
    background: #fff;
    overflow: hidden;
    z-index: 1;
    transition-duration: .5s;
    transform: translateX(100%);
    box-shadow: 3px -1px 20px 0px rgba(0, 0, 0, 0.18823529411764706);

    @include media-breakpoint-down(md) {
      transform: translateX(-15%);
      height: 100%;
    }
  }

  &__menu {
    padding: 30px;
    margin: 0;
    text-align: left;
    margin-left: 15%;

  }

  &__contacts {
    margin-left: 15%;
    bottom: 0;
    position: absolute;
    padding: 30px;
  }

  &__menu-item {
    display: inline-block;
    margin-right: 20px;
    
    @include media-breakpoint-down(sm) {
      display: block;
      margin-bottom: 30px;
    }
  }

  &__menu-link {
    color: #000;
  }
}

