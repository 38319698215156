.superiority {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 32px;
  border-radius: 3px;
  margin-top: -60px;

  @include media-breakpoint-down(lg) {
    margin-top: 30px;
    flex-wrap: wrap;
    justify-content: start;
  }

  &__item {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(lg) {
      margin-bottom: 20px;
      flex-grow: 1;
    }

  }

  &__item-img {
    width: 41px;
    margin-right: 16px;
    text-align: center;
  }

  &__item-name {
    line-height: 20px;
  }

  &__item-accent {
    display: block;
    color: $primary-color;
    font-weight: 500;
  }
}