.header {
  background: #fff;
  height: 78px;
  display: flex;
  align-items: center;



  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  &__logo-city {
    position: relative;
    left: -5px;
    color: $primary-color;
  }

  &__logo-img {
    width: 44px;
    margin-right: 8px;
  }

  &__menu {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__menu-item {
    display: inline-block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin-right: 32px;
    letter-spacing: 0.8px;
  }

  &__contacts {

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__contacts-phone {
    font-weight: bold;
    font-size: 18px;

  }
}