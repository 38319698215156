.icon {
    font-size: 10px;
}

.icon:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("../../../../dest/img/sprites/sprite.svg");
    background-repeat: no-repeat;
    background-size: 0em 0em;
}

.no-svg .icon:before {
    background-image: url("../../../../dest/img/sprites/sprite.png");
}




