.shop-list {
  background: $bg-dark;
  padding: 30px;




  &__item {
    background: #fff;
    padding: 18px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-name {
    font-weight: bold;
  }


}



