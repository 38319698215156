@import "partials/normalize";
@import "partials/variables";
@import "partials/sprite";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&subset=cyrillic');
@import "../../node_modules/bootstrap/scss/bootstrap-reboot";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/modal";
@import "blocks/header";
@import "blocks/cover";
@import "blocks/superiority";
@import "blocks/product-list";
@import "blocks/shop-list";
@import "blocks/offcanvas";
@import "blocks/burger";
@import "blocks/sale";
@import "blocks/calculator";
@import "blocks/footer";


body {
    background: #c9e0e04d;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: $text-color;
}

.btn {
    height: 48px;
    background: $accent-color;
    padding: 0 18px;
    line-height: 48px;
    color: #fff;
    font-weight: 500;

    &:hover {
        color: #fff;
        background: darken($accent-color, 5%);
    }
}

@media (max-width: 991.98px) {
    .btn {
        height: 40px;
        background: #ff6c00;
        padding: 0 15px;
        line-height: 40px;
        color: #fff;
        font-weight: 500;
    }
}


.btn-contrast {
    background: #fff;
    color: $text-color;

    &:hover {
        color: $accent-color;
        background: #fff;
        border: 1px solid $accent-color;
    }
}


#map {
    width: 100%;
    height: 400px;
    margin: 0;
    padding: 0;
}