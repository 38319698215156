.sale {
  padding: 40px;
  background: #fff;
  border-radius: 3px;
  //margin-bottom: 48px;


  &__head-row {
    display: table;
    background: $primary-color;
    border-radius: 3px;
    opacity: 0.9;
    padding: 4px 15px;
    margin-bottom: 8px;

    @include media-breakpoint-only(xs) { font-size:19px; }
  }
  
  &__head-text {
    color: #333;
    font-size: 28px;
    padding-left: 15px;
    text-transform: uppercase;

    @include media-breakpoint-only(xs) { font-size:16px; }
  }

  &__head {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    color: #fff;
    font-size:38px;
    //margin-top: 100px;
    @include media-breakpoint-only(xs) { font-size:24px; }

  }

  &__item {
    position: relative;
    display: flex;
    background: $bg-dark;
    border-radius: 3px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  &__item-img {
    max-width: 562px;


    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
    }
  }

  &__item-content {
    padding: 8px 16px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item-name {
    font-size: 20px;
    font-weight: 500;
  }


  &__item-footer {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__item-price {
    font-size: 18px;
    font-weight: bold;
  }

}