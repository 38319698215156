.cover {
  display: flex;
  width: 100%;
  height: calc(80vh - 80px);
  background-image: url('../../img/cover.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  align-items: center;
  animation: bg 20s infinite linear;

  
  @keyframes bg {
    0% {
      background-image: url('../../img/cover5.jpg');
    }
    50% {
      background-image: url('../../img/cover5.jpg');
    }
    51% {
      background-image: url('../../img/cover1.jpg');
      transition-duration: .10s;
    }
    100% {
      background-image: url('../../img/cover1.jpg');
      transition-duration: .10s;
    }

  }
  
  
  &__head {
    display: inline-block;
    color: #fff;
    font-size:36px;
  //margin-top: 100px;
    @include media-breakpoint-only(xs) { font-size:24px; }

  }
  
  &__head-row {
    display: table;
    background: $primary-color;
    border-radius: 3px;
    opacity: 0.9;
    padding: 4px 15px;
    margin-bottom: 8px;
    width: 100%;
  }

  &__btn {
    margin-right: 10px;
  }
  &__form form{
    background: #fff;
    padding: 15px;
  }

  &__form h5 {
    color: #fff;
    background: #35ae68;
    padding: 5px 15px;
  }
  &__btn_calc {
    @include media-breakpoint-down(sm) { margin-top: 15px; }
  }
}