.product-list {
  padding: 30px;
  background: #fff;
  border-radius: 3px 3px 0 0;
  //margin-bottom: 48px;



  &__item {
    position: relative;
    display: flex;
    background: $bg-dark;
    border-radius: 3px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: 20px;
    }
  }

  &__item-img {
    width: 140px;
    height: 100%;


    @include media-breakpoint-down(sm) {
      width: 100%;
      height: auto;
    }
  }

  &__item-content {
    padding: 8px 16px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &__item-name {
    font-size: 20px;
    font-weight: 500;
  }


  &__item-footer {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__item-price {
    font-size: 19px;
    font-weight: bold;
    color:red;
  }

  &__item-btn {
    margin: 0;
  }

  &__pay_a {
    @include media-breakpoint-only(md) { display: none }
  }

  &__icons {
    @include media-breakpoint-up(lg) { display: none!important; }
    @include media-breakpoint-only(xs) { display: none!important; }
  }

  &__lead {
    color: #27ae60;
    font-size: 18px;
    margin-top: 1rem;

    @include media-breakpoint-only(xs) { font-size:14px; }
  }

  &__brus-form img{
    max-width: 200px;
    position: absolute;
    margin: auto;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &__modal-left {
    background: #fff;
    padding:20px;

    @include media-breakpoint-down(md) { display: none }
  }

  &__contacts {
    position: absolute;
    bottom: 0;
    padding-bottom: 20px;
  }

  &__sale {
    font-size: 15px;
    margin-bottom: auto;
    color: $primary-color;
  }

  &__sale-content {
    color:$sale-color;
  }
  &__modal-right {
    padding-left: 0;

    @include media-breakpoint-down(md) { padding:15px }

    
  }
  &__frame {
    width:500px;
  
  @include media-breakpoint-down(md) { width:100% }
  }
}

.close {float: right;font-size: 1.5rem;line-height: 1;color: #000;text-shadow: 0 1px 0 #fff;opacity: .5;border: none;background: none;}
.modal-title{font-size: 1.25rem;font-weight: 400}
.modal-content {border: none;border-radius: 0}


