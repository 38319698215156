.burger {
  width: 30px;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  &__line1, &__line2, &__line3 {
    width: 30px;
    height: 2px;
    background: #000;
    margin: 8px auto;
    transition-duration: .3s;
  }

  &#{&}_on {

    .line1 {
      transform: translateY(10px) rotate(135deg);
    }

    .line2 {
      opacity: 0;
    }

    .line3 {
      transform: translateY(-10px) rotate(-135deg);
    }
  }

}


