.calculator {
  padding: 30px;

  &__item {
    background: #fff;
    padding: 18px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-name {
    font-weight: bold;
  }

  h2 {
    text-align: center;
  }
  &__form {
    background: #f7f8fa;
  }

  &__itog {
    margin-bottom: 15px;
    font-weight: bold;
  }
}



